//=require ../../node_modules/bootstrap/dist/js/bootstrap.min.js
//=require ../../node_modules/owl.carousel/dist/owl.carousel.min.js
//=require ../../node_modules/swiper/dist/js/swiper.min.js
//=require ../../node_modules/lightbox2/dist/js/lightbox.min.js
//=require ../../node_modules/waypoints/lib/jquery.waypoints.min.js
//=require ../../node_modules/headroom.js/dist/headroom.min.js
//=require ../../node_modules/headroom.js/dist/jQuery.headroom.min.js

/* jshint -W030 */
!function($, window) {
    "use strict";

    // Vars
    var $doc = $(document), $win = $(window), SITE = SITE || {};

    SITE = {
        // Init
        init: function() {
            var obj, self = this;
            for (obj in self) if (self.hasOwnProperty(obj)) {
                var _method = self[obj];
                void 0 !== _method.selector && void 0 !== _method.init && $(_method.selector).length > 0 && _method.init();
            }
        },

        //Multi Level Menu
        MultiLevelMenu: {
            selector: ".menu-wrapper",
            init: function() {
                var base = this, container = $(base.selector);

                var el = $(".menu-wrapper ul li a, .menu-wrapper ul li .nolink");
                var elActive = $(".menu-wrapper > ul").find("a.active");

                elActive.parent("li").addClass("active");
                elActive.parent("li").parents("li").addClass("active-trail");

                $(".menu-wrapper ul li").each(function() {
                  if($(this).children("ul").length) {
                    $(this).parent("ul").addClass("sublvl");
                  }
                });

                el.each(function() {
                  if($(this).next("ul").length) {
                    $(this).after('<span class="expand"></span>');
                  }
                });

                $(".menu-wrapper ul li .expand").on("click", function() {
                  $(this).parent("li").toggleClass('open');
                });
            }
        },

        //Data to BG
        DataToBg: {
            selector: "*[data-bg]",
            init: function() {
                var base = this, container = $(base.selector);

                container.each(function() {
                    var bg = $(this).data('bg');
                    if (bg.substr(0, 1) === '#') {
                        $(this).css({
                            'background-color': bg
                        });
                    }else{
                        $(this).css({
                            'background-image': 'url('+bg+')'
                        });
                    }
                });
            }
        },

        // Fixed navigation
        NavScrolled: {
            selector: ".main-header",
            init: function () {
                var base = this, container = $(base.selector);

                container.headroom({
                  "offset": 150,
                  "tolerance": {
                    down : 10,
                    up : 20
                  },
                  onUnpin : function() {

                  },
                });
            }
        },

        // Fixed cta
        ctaScrolled: {
            selector: ".front .cta",
            init: function () {
                var base = this, container = $(base.selector);
                var ctaPos = container.offset().top;

                $win.scroll(function() {
                    if ($(document).scrollTop() > ctaPos + 108) {
                        container.addClass("scrolled-trans");
                    } else {
                        container.removeClass("scrolled-trans");
                    }

                    if ($(document).scrollTop() > ctaPos + 120) {
                        container.addClass("scrolled");
                    } else {
                        container.removeClass("scrolled");
                    }
                });
            }
        },

        // Fixed cta
        cta2Scrolled: {
          selector: ".front .cta2",
          init: function () {
            var base = this, container = $(base.selector);
            var ctaPos = container.offset().top;

            $win.scroll(function() {
              if ($(document).scrollTop() > ctaPos + 108) {
                container.addClass("scrolled-trans");
              } else {
                container.removeClass("scrolled-trans");
              }

              if ($(document).scrollTop() > ctaPos + 120) {
                container.addClass("scrolled");
              } else {
                container.removeClass("scrolled");
              }
            });
          }
        },

        // Burger menu
        BurgerMenu: {
            selector: ".trigger-menu",
            init: function () {
                var base = this, container = $(base.selector);
                $(document).on('click', base.selector, function(e) {
                    e.preventDefault();
                    $(this).toggleClass('open');
                    $('body').toggleClass('open-menu');
                });
            }
        },

        // on scroll animation
        OsAnimation: {
            selector: ".os-animation, .staggered-animation",
            init: function () {
                function onScrollInit( items, trigger ) {
                    items.each( function() {
                        var osElement = $(this),
                            osAnimationClass = osElement.attr('data-os-animation'),
                            osAnimationDelay = osElement.attr('data-os-animation-delay');

                        osElement.css({
                            '-webkit-animation-delay':  osAnimationDelay,
                            '-moz-animation-delay':     osAnimationDelay,
                            'animation-delay':          osAnimationDelay
                        });

                        var osTrigger = ( trigger ) ? trigger : osElement;

                        osTrigger.waypoint(function() {
                            osElement.addClass('animated').addClass(osAnimationClass);
                        },{
                            triggerOnce: true,
                            offset: '90%'
                        });
                    });
                }

                onScrollInit( $('.os-animation') );
                $('.staggered-animation-container').each(function() {
                    onScrollInit( $(this).find('.staggered-animation'), $(this) );
                });
            }
        },


        // Swiper gallery
        ContentGallery: {
            selector: ".paragraphs-galerie .owl-carousel",
            init: function () {
                var base = this, container = $(base.selector);

                container.owlCarousel({
                    items:2,
                    margin:20,
                    loop:true,
                    center:true,
                    nav:true,
                    dots:false,
                    autoplay:true,
                    responsive:{
                        0:{
                            items:1
                        },
                        576:{
                            items:2
                        },
                    }
                });
            }
        },

        // Close messages
        MessagesClose: {
            selector: ".messages",
            init: function () {
                var base = this, container = $(base.selector);
                container.prepend('<a href="#" class="close">×</a>');

                $(document).on("click", ".messages .close", function(e) {
                    e.preventDefault();
                    $(this).parents(".messages").slideUp(200);
                });
            }
        },

        // Lang menu
        LangMenu: {
            selector: "ul.language-switcher-locale-url",
            init: function () {
                var base = this, container = $(base.selector), activeLang = container.find('li.active a').text();

                // create span with active lang
                container.parent().prepend('<span class="label">'+activeLang+'<i class="fa fa-angle-down" aria-hidden="true"></i></span>');
                // hide  active lang in ul
                container.find('li.active').remove();

                //show ul
                container.parent().find('.label').on('click', function (e){
                    e.stopPropagation();
                    container.slideToggle(300);
                    $(this).find('.fa').toggleClass('fa-caret-down');
                    $(this).find('.fa').toggleClass('fa-caret-up');
                });

                //hide ul
                $(document).on('click', function(e) {
                    var $clicked = $(e.target);
                    if (!$clicked.parents().hasClass("lang")) {
                        container.slideUp(300);
                        container.parent().find('.label').find('.fa').removeClass('fa-caret-up');
                        container.parent().find('.label').find('.fa').addClass('fa-caret-down');
                    }
                });

                $(document).scroll(function() {
                    if ($(document).scrollTop() >= 50) {
                        container.slideUp(300);
                        container.parent().find('.label').find('.fa').removeClass('fa-caret-up');
                        container.parent().find('.label').find('.fa').addClass('fa-caret-down');
                    }
                });
            }
        },

        // leaderboard
        leaderboard: {
            selector: ".leaderboard .swiper-container",
            init: function () {
                var base = this, container = $(base.selector);

                var mySwiper = new Swiper (base.selector, {
                    effect: 'fade',
                    loop: true
                });
            }
        },

        // Resize
    }, $win.resize(function() {

        // Scroll
    }), $win.scroll(function() {
        SITE.NavScrolled.init();

        // Ready
    }), $doc.ready(function() {
        SITE.init();
    });
}(jQuery, this);
